import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import dashboardIcon from "../../../assets/svgImages/dashboardIcon.svg";
import Loader from "../../../components/Loader";
import edgent from "../../../assets/homepage/edgentWhite.svg";

const NavbarLoggedInNew = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer state
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setLoading(true);
    localStorage.setItem("access_token", "");
    localStorage.setItem("token_expiration", "0");
    handleClose();
    setTimeout(() => {
      setLoading(false);
      navigate("/");
    }, 1000);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        backgroundColor: "#0181BB",
        height: "100%",
        padding: "16px",
        color: "#fff",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ marginBottom: "16px", fontFamily: "Be Vietnam Pro" }}>
        <img
          src={edgent}
          alt="Logo"
          style={{ width: "70px", height: "45px" }}
        />
      </Box>
      <List>
        <ListItem button onClick={() => navigate("/dashboard")}>
          <img
            src={dashboardIcon}
            alt="Dashboard"
            style={{ marginRight: "8px", width: "18px" }}
          />
          <ListItemText
            primary="Dashboard"
            sx={{
              fontFamily: "Be Vietnam Pro",
              ".MuiTypography-root": { fontFamily: "Be Vietnam Pro" },
            }}
          />
        </ListItem>
        <ListItem button onClick={() => navigate("/chat")}>
          <ChatBubbleOutlineOutlinedIcon
            sx={{ color: "white", marginRight: "8px" }}
          />
          <ListItemText
            primary="Chat"
            sx={{
              fontFamily: "Be Vietnam Pro",
              ".MuiTypography-root": { fontFamily: "Be Vietnam Pro" },
            }}
          />
        </ListItem>
        <ListItem button onClick={() => navigate("/profile")}>
          <AccountCircleOutlinedIcon
            sx={{ color: "white", marginRight: "8px" }}
          />
          <ListItemText
            primary="Profile"
            sx={{
              fontFamily: "Be Vietnam Pro",
              ".MuiTypography-root": { fontFamily: "Be Vietnam Pro" },
            }}
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      // position="sticky"
      sx={{
        backgroundColor: "white",

        height: "0%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", p: 3 }}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "64px !important",
            width: "100%",
          }}
        >
          {/* Left Section: Drawer Menu Icon and Logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                color: "rgb(134, 134, 134)",
              }}
            >
              <MenuIcon />
            </IconButton>

            {/* Drawer Implementation */}
            <Drawer
              anchor="left"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0)" }, // Transparent backdrop
              }}
            >
              {drawerContent}
            </Drawer>
          </Box>
        </Toolbar>
      </Box>
      {loading && <Loader />}
    </AppBar>
  );
};

function SideBar() {
  return (
    <div className="sidebar">
      <NavbarLoggedInNew />
    </div>
  );
}

export default SideBar;
